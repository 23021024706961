import React from 'react';
import Container from 'react-bootstrap/Container';
import { BgImage } from 'gbimage-bridge';

interface IProps {
  backgroundImg: any;
}

const FinalOutpostBanner: React.FC<IProps> = ({ backgroundImg }) => (
  <BgImage image={backgroundImg}>
    <Container className="header-content-col" fluid />
  </BgImage>
);

export default FinalOutpostBanner;
