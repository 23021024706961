import React from 'react';
import Button from 'react-bootstrap/Button';
import { finalOutpostLearnMoreUrl } from '../../constants';

const LearnMoreBtn: React.FC = () => (
  <a target="_blank" href={finalOutpostLearnMoreUrl} rel="noreferrer">
    <Button variant="primary" className="px-lg-5 py-3 very-bold">
      Learn More
    </Button>
  </a>
);

export default LearnMoreBtn;
