import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FinalOutpostBanner from './Banner';
import LearnMoreBtn from './LearnMore';
import {
  appStoreUrls,
  TitleDescriptionPair,
  FinalOutpostDescriptionPairs,
} from '../../constants';
import AppleStore from '../../images/final-outpost/app-store-black.svg';
import PlayStore from '../../images/final-outpost/play-store-black.svg';
import SteamStore from '../../images/final-outpost/wishlist-steam.svg';

function getRandomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function probability(n) {
  return Math.random() < n;
}

interface IProps {
  left: TitleDescriptionPair;
  right: TitleDescriptionPair;
}

const DescriptionRow: React.FC<IProps> = ({ left, right }) => (
  <Row className="justify-content-between description-row">
    <Col className="description-card" xs={4} xl={3}>
      <h4 className="very-bold upper">{left.title}</h4>
      <p>{left.description}</p>
    </Col>
    <Col className="description-card text-right" xs={4} xl={3}>
      <h4 className="very-bold upper">{right.title}</h4>
      <p>{right.description}</p>
    </Col>
  </Row>
);

const BannerContainer: React.FC = () => {
  const all = useStaticQuery(
    graphql`
      query {
        bannerBackgroundDesktop: file(
          relativePath: { eq: "final-outpost/banner/desktop/background.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bannerBackgroundMobile: file(
          relativePath: { eq: "final-outpost/banner/mobile/background.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bannerLightDimOnlyMobile: file(
          relativePath: { eq: "final-outpost/banner/mobile/light-dim.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bannerLightOnOnlyMobile: file(
          relativePath: { eq: "final-outpost/banner/mobile/light-on.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bannerLightDimOnlyDesktop: file(
          relativePath: { eq: "final-outpost/banner/desktop/light-dim.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        bannerLightOnOnlyDesktop: file(
          relativePath: { eq: "final-outpost/banner/desktop/light-on.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const { build, manage, craft, survive } = FinalOutpostDescriptionPairs;
  const bannerBackgroundMobile = getImage(all.bannerBackgroundMobile);
  const bannerBackgroundDesktop = getImage(all.bannerBackgroundDesktop);

  const bannerLightOffStackMobile = bannerBackgroundMobile;
  const bannerLightOffStackDesktop = bannerBackgroundDesktop;

  const bannerLightDimStackMobile = [
    bannerBackgroundMobile,
    getImage(all.bannerLightDimOnlyMobile),
  ].reverse();

  const bannerLightDimStackDesktop = [
    bannerBackgroundDesktop,
    getImage(all.bannerLightDimOnlyDesktop),
  ].reverse();

  const bannerLightOnStackMobile = [
    bannerBackgroundMobile,
    getImage(all.bannerLightOnOnlyMobile),
  ].reverse();

  const bannerLightOnStackDesktop = [
    bannerBackgroundDesktop,
    getImage(all.bannerLightOnOnlyDesktop),
  ].reverse();

  const lightOff = 'lightOff';
  const lightDim = 'lightDim';
  const lightOn = 'lightOn';

  const [shownImg, setShownImg] = useState(lightOn);
  const [shownImgDuration, setShownImgDuration] = useState(
    getRandomInRange(800, 2500)
  );

  const getFlicker = () => {
    let nextImg = shownImg === lightDim ? lightOff : lightDim;
    let nextImgDelay;

    if (probability(0.16)) {
      if (probability(0.5)) {
        nextImg = lightOff;
        nextImgDelay = 1;
      } else {
        nextImg = lightOn;
        nextImgDelay = getRandomInRange(500, 1000);
      }
    } else {
      nextImgDelay = getRandomInRange(20, 70);
    }

    return {
      nextImg,
      nextImgDelay,
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const { nextImgDelay, nextImg } = getFlicker();

      setShownImg(nextImg);
      setShownImgDuration(nextImgDelay);
    }, shownImgDuration);
    return () => clearInterval(interval);
  }, [shownImg, shownImgDuration]);

  const getClassName = (targetImg) =>
    shownImg === targetImg
      ? 'final-outpost-banner-high-z'
      : 'final-outpost-banner-low-z';

  return (
    <div className="final-outpost-animated-background-container">
      {/*
      gatsby-background-image doesn't support stacked and art-directed images
      at the same time, so this workaround is needed to change background
      images on mobile/desktop.
      */}
      <div className="d-block d-lg-none">
        <div className={getClassName(lightOff)}>
          <FinalOutpostBanner backgroundImg={bannerLightOffStackMobile} />
        </div>
        <div className={getClassName(lightDim)}>
          <FinalOutpostBanner backgroundImg={bannerLightDimStackMobile} />
        </div>
        <div className={getClassName(lightOn)}>
          <FinalOutpostBanner backgroundImg={bannerLightOnStackMobile} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className={getClassName(lightOff)}>
          <FinalOutpostBanner backgroundImg={bannerLightOffStackDesktop} />
        </div>
        <div className={getClassName(lightDim)}>
          <FinalOutpostBanner backgroundImg={bannerLightDimStackDesktop} />
        </div>
        <div className={getClassName(lightOn)}>
          <FinalOutpostBanner backgroundImg={bannerLightOnStackDesktop} />
        </div>
      </div>

      <div className="final-outpost-header-container">
        <Container>
          <Row>
            <Col className="header-content-col">
              <div className="header-content-title-container">
                <h3 className="final-outpost-title text-center upper very-bold">
                  Final Outpost
                </h3>
              </div>

              <p className="text-center title-description mb-lg-0 d-lg-none">
                Build your Outpost. Manage your citizens.
                <span className="hide-on-lg-mobile-up"> </span>
                <br className="shown-on-lg-mobile-up"/>
                Survive the zombie apocalypse.
              </p>

              <p className="text-center title-description mb-lg-0 d-none d-lg-block">
                Build your outpost. Manage your citizens. Craft tools and
                weapons.
              </p>

              <p className="text-center title-description d-none d-lg-block">
                Survive the zombie apocalypse.
              </p>

              <div className="final-outpost-banner-stores-container">
                <a target="_blank" href={appStoreUrls.apple} rel="noreferrer">
                  <img
                      src={AppleStore}
                      alt=""
                      className="final-outpost-banner-store-logo"
                  />
                </a>

                <a target="_blank" href={appStoreUrls.google} rel="noreferrer">
                  <img
                      src={PlayStore}
                      alt=""
                      className="final-outpost-banner-store-logo"
                  />
                </a>
              </div>
              <div className="final-outpost-banner-stores-container">
                <a target="_blank" href={appStoreUrls.steam_games_page} rel="noreferrer">
                  <img
                      src={SteamStore}
                      alt=""
                      className="final-outpost-steam-btn-container"
                  />
                </a>
              </div>

              <div className="d-none d-lg-block desktop-description-container">
                <DescriptionRow left={build} right={manage}/>
                <DescriptionRow left={craft} right={survive}/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="final-outpost-banner-btn text-center d-none d-lg-block">
        <LearnMoreBtn/>
      </div>
    </div>
  );
};

export default BannerContainer;
