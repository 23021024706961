import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import FinalOutpostBannerContainer from '../components/final-outpost/BannerContainer';
import LearnMoreBtn from '../components/final-outpost/LearnMore';
import PageWrapper from '../components/PageWrapper';
import { getPageTitle } from '../utils';

import {
  TitleDescriptionPair,
  FinalOutpostDescriptionPairs,
  finalOutpostLearnMoreUrl,
} from '../constants';

const screenshotImgClassname = 'screenshot-img';

const { build, manage, craft, survive } = FinalOutpostDescriptionPairs;

const descriptions = [build, manage, craft, survive];

const pageTitle = getPageTitle('Final Outpost');

interface IProps {
  item: TitleDescriptionPair;
}

const DescriptionCard: React.FC<IProps> = ({ item }) => (
  <div className="final-outpost-description-mobile-card">
    <h4 className="very-bold upper">{item.title}</h4>
    <p>{item.description}</p>
  </div>
);

interface ScreenShot {
  key: string;
  img: React.ReactElement;
  description: string[];
}

interface ScreenShotIProps {
  item: ScreenShot;
  index: number;
}

const ScreenshotDescriptionCard: React.FC<ScreenShotIProps> = ({
  item,
  index,
}) => (
  <Row
    className={`${index % 2 === 1 && 'odd-card'} final-outpost-screenshot-card`}
  >
    <Col xs={12} sm={7} lg={8}>
      {item.img}
    </Col>
    <Col xs={12} sm={5} lg={4}>
      <div className="outpost-card-text-container">
        {item.description.map((paragraph) => (
          <p key={paragraph}>{paragraph}</p>
        ))}
      </div>
    </Col>
  </Row>
);

const buildScreenshot: ScreenShot = {
  key: 'build',
  img: (
    <StaticImage
      className={screenshotImgClassname}
      src="../images/final-outpost/app-screenshots/1Build.jpg"
      alt=""
    />
  ),
  description: [
    'Construct and upgrade a variety of buildings to accommodate the needs of your citizens.',
    'This is your Outpost. Manage it well and watch it grow.',
  ],
};

const townScreenshot: ScreenShot = {
  key: 'town',
  img: (
      <StaticImage
          className={screenshotImgClassname}
          src="../images/final-outpost/app-screenshots/2Manage.jpg"
          alt=""
      />
  ),
  description: [
    'Offer refuge to other survivors and give them jobs that will help your Outpost thrive.',
    'Make sure your citizens are doing the right jobs at the right times to ensure your citizens have enough food.',
  ],
};

const resourcesScreenshot: ScreenShot = {
  key: 'resources',
  img: (
      <StaticImage
          className={screenshotImgClassname}
          src="../images/final-outpost/app-screenshots/3Craft.jpg"
          alt=""
      />
  ),
  description: [
    'Build a workshop and use your vital resources to craft tools that enable your citizens to do more demanding jobs.',
    'Craft bows for hunting, hammers for metalworking, pickaxes for mining, and weapons to defend your walls.',
  ],
};

const peopleScreenshot: ScreenShot = {
  key: 'people',
  img: (
      <StaticImage
          className={screenshotImgClassname}
          src="../images/final-outpost/app-screenshots/4Survive.jpg"
          alt=""
      />
  ),
  description: [
    'In the zombie apocalypse, people are a resource.',
    'With dynamic weather, day/night cycle and changing seasons, managing your citizens efficiently becomes an even tougher challenge.',
  ],
};

const craftScreenshot: ScreenShot = {
  key: 'craft',
  img: (
    <StaticImage
      className={screenshotImgClassname}
      src="../images/final-outpost/app-screenshots/5Defend.jpg"
      alt=""
    />
  ),
  description: [
    'Fend off hordes of zombies by training guards and snipers, and building machine guns and watch towers.',
    'Surviving these dangerous blood moon nights will earn you skill points.',
  ],
};

const damageScreenshot: ScreenShot = {
  key: 'damage',
  img: (
    <StaticImage
      className={screenshotImgClassname}
      src="../images/final-outpost/app-screenshots/6Base.jpg"
      alt=""
    />
  ),
  description: [
    'Put your hard-earned skill points to use by improving your capabilities with the skill tree.',
    'Ensure the survival of your citizens by guiding them from novice to warrior.',
  ],
};



const ScreenshotDescriptions = [
  buildScreenshot,
  townScreenshot,
  resourcesScreenshot,
  peopleScreenshot,
  craftScreenshot,
  damageScreenshot,
];

const IndexPage: React.FC = () => (
  <PageWrapper>
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content="This is Final Outpost." />
    </Helmet>

    <FinalOutpostBannerContainer />

    <Container fluid className="final-outpost-section text-section d-lg-none">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                {descriptions.map((item) => (
                  <DescriptionCard key={item.title} item={item} />
                ))}
                <div className="text-center learn-more-mobile-btn-container">
                  <LearnMoreBtn />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
    <Container fluid className="final-outpost-section">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                {ScreenshotDescriptions.map((item, index) => (
                  <ScreenshotDescriptionCard
                    key={item.key}
                    item={item}
                    index={index}
                  />
                ))}
                <div className="final-outpost-view-game-btn-container">
                  <a
                    target="_blank"
                    href={finalOutpostLearnMoreUrl}
                    rel="noreferrer"
                  >
                    <Button
                      variant="primary"
                      className="px-lg-5 py-3 very-bold"
                    >
                      View The Game
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export default IndexPage;
